import { Register } from '@/models/register'
import RegisterForm from './RegisterForm/RegisterForm.vue'
import Verify from './Verify/Verify.vue'
import { Languages } from '@/classes/Languages'
import { ValidatorLocalize } from '@/util/ValidatorLocalize'
import Alert from '@/components/Alert/Alert.vue'
import ThemeLoader from '@/util/theme-loader'

export default {
  name: 'Register',
  components: {
    RegisterForm,
    Verify,
    Alert,
  },
  data () {
    return {
      register: new Register(),
      languages: Languages.get(),
      logoResourceName: '',
      logoFooterResourceName: '',
    }
  },
  computed: {
    logoSrc () {
      return ThemeLoader.getLogoResource(this.logoResourceName)
    },
    bgFooter () {
      return ThemeLoader.getImageResource(this.logoFooterResourceName)
    },
  },
  methods: {
    goToLogin () {
      window.location.replace(window.location.origin + '#/login')
    },
    changeLanguage (langIsoCode) {
      this.userLanguage = langIsoCode
      this.$i18n.locale = langIsoCode
      localStorage.setItem('language', langIsoCode)
      ValidatorLocalize.setLanguage(langIsoCode)
    },
    setIsoFlag (isoCode) {
      const lang = isoCode === 'en' ? 'gb' : isoCode
      return require('./../../../../assets/img/flags/' + lang + '.svg')
    },
  },
  created () {
    this.$i18n.locale = this.$route.query.lang || this.$i18n.locale
  },
  mounted () {
    this.userLanguage = this.$i18n.locale.split('-')[0]
    const langIsoCode = localStorage.getItem('language') || this.userLanguage
    ValidatorLocalize.setLanguage(langIsoCode)

    const whiteLabelBrand = window.whiteLabelInfo
    this.logoResourceName = 'logo-login-' + whiteLabelBrand.hash + '.' + whiteLabelBrand.logo_login_extension
    this.logoFooterResourceName = 'footer-' + whiteLabelBrand.hash + '.' + whiteLabelBrand.bg_footer_extension
  },
}
