export class Register {
  step = 1
  show = false
  success = false

  firstName = null
  lastName = null
  userName = ''
  countryPhone = ''
  phone = ''
  email = ''
  privacy = false
  terms = false
  token = ''
  code = null
  googleReCaptchaResponse = ''

  reset () {
    this.show = false
    this.success = false
    this.firstName = null
    this.lastName = null
    this.userName = ''
    this.countryPhone = ''
    this.phone = ''
    this.email = ''
    this.privacy = false
    this.terms = false
    this.token = ''
    this.code = null
    this.googleReCaptchaResponse = ''
  }
}
