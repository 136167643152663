/* eslint-disable no-undef */
import getEnv from '@/util/env'

export default class ReCaptcha {
  static getToken () {
    return new Promise((resolve, reject) => {
      grecaptcha.ready(() => {
        grecaptcha
          .execute(getEnv('recaptcha_v3_site_key'), { action: 'submit' })
          .then((token) => {
            resolve(token)
          })
          .catch((error) => {
            console.error('Error en reCAPTCHA:', error)
            reject(error)
          })
      })
    })
  }

  static async assignCaptchaToObject (object) {
      const token = await ReCaptcha.getToken()
      if (!token) {
          throw new Error(this.$t('error reCAPTCHA'))
      }

      object.googleReCaptchaResponse = token
      return object
  }
}
