import EventBus from '@/util/EventBus'
import RegisterService from '@/services/register.service'
import ReCaptcha from '@/util/ReCaptcha.js'
import HoneyPotService from '@/services/honey-pot.service'

export default {
  name: 'Verify',
  props: {
    register: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      loading: false,
      manyAttempts: false,
      verifyCodeHelper: Array(4).fill(''),
      honeypotValue: '',
      honeypotFieldName: '',
      renderHoneypot: false,
      honeyPotInputFieldName: '',
      validFrom: '',
    }
  },
  watch: {
    verifyCodeHelper: {
      deep: true,
      handler (newVal) {
        if (!this.hasEmptyFields) {
          setTimeout(() => {
            this.register.code = this.verifyCodeHelper.join('')
            this.handleVerify()
          }, 50)
        }
      },
    },
  },
  computed: {
    hasEmptyFields () {
      return !this.verifyCodeHelper.every(val => val.trim().length === 1);
    },
  },
  methods: {
    setLastCharWithTimeout(index, value) {
      return new Promise((resolve) => {
        const lastChar = value.charAt(value.length - 1);
        setTimeout(() => {
          this.$set(this.verifyCodeHelper, index, lastChar);
          resolve(lastChar);
        });
      });
    },
    async focusNext (index, event) {
      const value = this.$refs.codeInputs[index].$el.querySelector('input').value

      if (value && value.length > 1) {
        await this.setLastCharWithTimeout(index, value)
      }

      if (!/^\d*$/.test(value)) {
        this.$set(this.verifyCodeHelper, index, '');
        return
      }

      if (value.length === 1 && index < 5) {
        this.$nextTick(() => {
          const nextInput = this.$refs.codeInputs[index + 1]

          if (nextInput) {
            nextInput.$el.querySelector('input').focus()
          }
        })
      }
    },
    handleBackspace (index, event) {
      if (event.key === 'Backspace' && !this.verifyCodeHelper[index] && index > 0) {
        this.$nextTick(() => {
          const prevInput = this.$refs.codeInputs[index - 1]
          if (prevInput) {
            prevInput.$el.querySelector('input').focus()
          }
        })
      }
    },
    handlePaste (index, event) {
      const text = event.clipboardData.getData('Text')
      event.preventDefault()
      setTimeout(() => {
        const newCodeHelper = [...this.verifyCodeHelper]
        for (let i = 0; i < text.length; i++) {
          if (index + i < 4) {
            newCodeHelper[index + i] = text[i]
          }
        }
        this.verifyCodeHelper = newCodeHelper
      })
    },
    async handleVerify () {
      const isValid = await this.$refs.observer.validate()
      if (!isValid) {
        return
      }

      this.loading = true

      const dataToSend = { ...this.register }
      dataToSend[this.honeyPotInputFieldName] = this.honeypotValue
      dataToSend.honeyPotInputFieldName = this.honeyPotInputFieldName
      dataToSend.valid_from = this.validFrom

      try {
        await ReCaptcha.assignCaptchaToObject(dataToSend)
        console.log(dataToSend)
      } catch (error) {
        this.loading = false
        EventBus.$emit('showAlert', 'danger', this.$t('error de validación'))
        return
      }

      RegisterService.verify(dataToSend).then(
        (res) => {
          this.register.step = 3
          this.register.reset()
        },
        (error) => {
          if (error.response.status === 422) {
            console.log(error.response.data)
            EventBus.$emit('showValidationErrors', 'danger', error.response.data)
          } else if (error.response.data.code === 'verify.manyAttempts') {
            this.manyAttempts = true
          } else if ([403, 409, 400, 429].includes(error.response.status)) {
            EventBus.$emit('showAlert', 'danger', error.response.data.error)
          } else {
            EventBus.$emit('showAlert', 'danger', this.$t('Ocurrio un error al intentar de registrarse'))
          }
        },
      )
      .finally(() => {
        this.loading = false
      })
    },
    async reSendCode () {
      this.loading = true
      const dataToSend = {
        token: this.register.token,
        googleReCaptchaResponse: '',
        [this.honeyPotInputFieldName]: this.honeypotValue,
        honeyPotInputFieldName: this.honeyPotInputFieldName,
        valid_from: this.validFrom,
      }
      try {
        await ReCaptcha.assignCaptchaToObject(dataToSend)
        console.log(dataToSend)
      } catch (error) {
        this.loading = false
        EventBus.$emit('showAlert', 'danger', this.$t('error de validación'))
        return
      }
      RegisterService.reSendCode(dataToSend).then(
        () => {
          EventBus.$emit('showAlert', 'success', this.$t('Se ha reenviado el codigo correctamente'))
        },
        (error) => {
          if (error.response.status === 422) {
            EventBus.$emit('showValidationErrors', 'danger', error.response.data)
          } else if (error.response.data.code === 'verify.manyAttempts') {
            this.manyAttempts = true
          } else if (error.response.status === 429) {
            EventBus.$emit('showAlert', 'danger', error.response.data.error)
          } else {
            EventBus.$emit('showAlert', 'danger', this.$t('Ocurrio un error al reenviar el codigo'))
          }
        },
      )
      .finally(() => {
        this.loading = false
      })
    },
  },
  async created () {
    try {
      const data = await HoneyPotService.getHoneyPotFieldName()
      this.honeyPotFieldName = data.name
      this.honeyPotInputFieldName = data.name
      this.validFrom = data.valid_from
      this.renderHoneypot = true
    } catch (error) {
      EventBus.$emit('showAlert', 'danger', this.$t('Ocurrio un error al intentar inicializar el formulario'))
    }
  },
}
