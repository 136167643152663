import EventBus from '@/util/EventBus'
import RegisterService from '@/services/register.service'
import CountryService from '@/services/country.service.js'
import SelectCountry from '@/components/ContactSource/SelectCountry/SelectCountry.vue'
import ReCaptcha from '@/util/ReCaptcha.js'
import HoneyPotService from '@/services/honey-pot.service'

export default {
  name: 'RegisterForm',
  props: {
    register: {
      type: Object,
      required: true,
    },
  },
  components: {
    SelectCountry,
  },
  data () {
    return {
      loading: false,
      countries: [],
      honeypotValue: '',
      honeypotFieldName: '',
      renderHoneypot: false,
      honeyPotInputFieldName: '',
      validFrom: '',
    }
  },
  methods: {
    getAllCountries () {
      CountryService
          .getAllCountries()
          .then(
              (response) => {
                  this.countries = response.map((country) => {
                      return {
                          value: country.iso_code_2,
                          text: `${country.name} +(${country.prefix})`,
                      }
                  })
              },
              () => {},
          )
    },
    async handleRegister () {
      const isValid = await this.$refs.observer.validate()
      if (!isValid) {
        return
      }

      this.loading = true

      try {
        await ReCaptcha.assignCaptchaToObject(this.register)
      } catch (error) {
        this.loading = false
        EventBus.$emit('showAlert', 'danger', this.$t('error de validación'))
        return
      }

      const dataToSend = { ...this.register }
      dataToSend[this.honeyPotInputFieldName] = this.honeypotValue
      dataToSend.honeyPotInputFieldName = this.honeyPotInputFieldName
      dataToSend.valid_from = this.validFrom

      RegisterService.register(dataToSend)
          .then(
              (response) => {
                  this.loading = false
                  this.register.step = 2
                  this.register.token = response.token
              },
              (error) => {
                  if (error.response.status === 422) {
                      console.log(error.response.data)
                      EventBus.$emit('showValidationErrors', 'danger', error.response.data)
                  } else if ([403, 409, 400, 429].includes(error.response.status)) {
                      EventBus.$emit('showAlert', 'danger', error.response.data.error)
                  } else {
                    EventBus.$emit('showAlert', 'danger', this.$t('Ocurrio un error al intentar de registrarse'))
                  }
                  this.loading = false
              },
          )
    },
  },
  async created () {
    this.getAllCountries()
    try {
      const data = await HoneyPotService.getHoneyPotFieldName()
      this.honeyPotFieldName = data.name
      this.honeyPotInputFieldName = data.name
      this.validFrom = data.valid_from
      this.renderHoneypot = true
    } catch (error) {
      EventBus.$emit('showAlert', 'danger', this.$t('Ocurrio un error al intentar inicializar el formulario'))
    }
  },
}
