import { Service } from './service'

const PATH = 'register'

class RegisterService extends Service {
  register (data = {}) {
    return this.post(PATH + '/register', data)
  }

  verify (data = {}) {
    return this.post(PATH + '/verify', data)
  }

  reSendCode (data = {}) {
    return this.post(PATH + '/resend-activation-code', data)
  }
}

export default new RegisterService()
